export const url = `${process.env.URL || 'https://www.rikhilrai.com'}`;

export interface IApp {
  id: string;
  title: string;
  type: 'MOBILE' | 'WEB';
  shortDescription: string;
  shortDescriptionAlt?: string;
  description: string;
  image: string;
  coloredImage: string;
  path: string;
  leftColor: string;
  rightColor: string;
  iconBackgroundColor: string;
}

export interface IMobileApp extends IApp {
  appStoreId: string;
  appStoreLink: string;
  googlePlayLink: string;
  googlePackage: string;
  deepLinkBase: string;
  deepLinkHomePath: string;
  queryMapping: (searchParams: URLSearchParams) => string;
}

export interface IWebApp extends IApp {
  webLink: string;
}

export const MOBILE_APPS: IMobileApp[] = [
  {
    id: 'bhajan-lyrics',
    title: 'Bhajan Lyrics',
    type: 'MOBILE',
    shortDescription: 'Application for Bhajans (devotional songs) lyrics',
    description:
      'Bhajan lyrics picket diary. Access all your favourite Bhajans at the touch of a button. Filter Bhajans by category, bookmark your favourites and share lyrics with your friends.',
    image: '/bhajan-lyrics/logo.png',
    coloredImage: '/bhajan-lyrics/logo-color.png',
    path: '/projects/bhajan-lyrics',
    leftColor: 'rgb(194, 65, 12)',
    rightColor: 'rgb(251, 146, 60)',
    appStoreId: 'id1553323738',
    appStoreLink: 'https://apps.apple.com/us/app/bhajan-lyrics/id1553323738?utm_source=rikhilrai',
    googlePlayLink:
      'https://play.google.com/store/apps/details?id=com.developerrsquared.bhajanlyrics&utm_source=rikhilrai',
    googlePackage: 'com.developerrsquared.bhajanlyrics',
    iconBackgroundColor: '#c2410c',
    deepLinkBase: 'bhajanlyrics://',
    deepLinkHomePath: 'bhajanlyrics://bhajans',
    queryMapping: (searchParams) => {
      if (searchParams.has('bhajanId')) {
        return `bhajans/${searchParams.get('bhajanId')}`;
      } else if (searchParams.has('settings')) {
        return 'settings';
      } else if (searchParams.has('about')) {
        return 'about';
      } else if (searchParams.has('tag')) {
        return `tags/${searchParams.get('tag')}`;
      } else if (searchParams.has('name') && searchParams.has('playlist')) {
        const bhajanIds = searchParams.get('playlist');
        const name = searchParams.get('name');
        return `playlists?name=${name}&playlist=${bhajanIds}`;
      }
      return 'bhajans';
    },
  },
  {
    id: 'alankaras',
    title: 'Alankaras',
    type: 'MOBILE',
    shortDescription: 'Innovate, practice and perfect musical patterns.',
    description: 'Innovate, practice and perfect musical patterns.',
    image: '/alankaras/logo.png',
    coloredImage: '/alankaras/logo-color.png',
    path: '/projects/alankaras',
    leftColor: 'rgb(190, 18, 60)',
    rightColor: 'rgb(251, 113, 133)',
    appStoreId: 'id1552787699',
    appStoreLink: 'https://apps.apple.com/us/app/alankaars/id1552787699?utm_source=rikhilrai',
    googlePlayLink: 'https://play.google.com/store/apps/details?id=com.alankaars&utm_source=rikhilrai',
    googlePackage: 'com.alankaars',
    iconBackgroundColor: '#be123c',
    deepLinkBase: 'alankaars://',
    deepLinkHomePath: 'alankaars://patterns',
    queryMapping: (searchParams) => {
      // TODO(rikhil): fill this in!
      return 'patterns';
    },
  },
  {
    id: 'ragas',
    title: 'Ragas',
    type: 'MOBILE',
    shortDescription: 'Discover Indian classical Ragas',
    description: 'Discover Indian classical Ragas',
    image: '/ragas/logo.png',
    coloredImage: '/ragas/logo-color.png',
    path: '/projects/ragas',
    leftColor: 'rgb(3, 105, 161)',
    rightColor: 'rgb(56, 189, 248)',
    appStoreId: 'id1602071930',
    appStoreLink: 'https://apps.apple.com/us/app/ragas/id1602071930?utm_source=rikhilrai',
    googlePlayLink: 'https://play.google.com/store/apps/details?id=com.developerrsquared.ragas&utm_source=rikhilrai',
    googlePackage: 'com.developerrsquared.ragas',
    iconBackgroundColor: '#b12b38',
    deepLinkBase: 'app://ragas/',
    deepLinkHomePath: 'app://ragas/home',
    queryMapping: (searchParams) => {
      // TODO(rikhil): fill this in!
      return 'home';
    },
  },
];

export const WEB_APPS: IWebApp[] = [
  {
    id: 'one-min-meditation',
    title: 'One Minute Meditation',
    type: 'WEB',
    shortDescription: 'Find your zen in just a minute',
    shortDescriptionAlt: '... find your zen now',
    description: 'Find your zen in just a minute. I just meditated for 1 minute. Can you?',
    image: '/one-min-meditation/logo.png',
    coloredImage: '/one-min-meditation/logo-color.png',
    path: '/projects/one-min-meditation',
    leftColor: 'rgb(15, 118, 110)',
    rightColor: 'rgb(45, 212, 191)',
    iconBackgroundColor: '#00aba9',
    webLink: 'https://www.oneminmeditation.com',
  },
];

export const APPS = ([] as (IMobileApp | IWebApp)[]).concat(MOBILE_APPS).concat(WEB_APPS);
